<template>
  <div>
    <div v-if="showArticleContent">
      <div>
        <div class="article-content">
          <div class="article-title" id="articleTitle">
            <img src="@/assets/images/title_decorate.png" alt="" class="title-decorate">
            {{noticeTitle}}
          </div>
          <div class="article-summary notice-summary">
            {{content}}
            <span class="line summary-line"></span>
          </div>
          <div class="atricle-detail" v-html="resourceDetail" id="uEditorContent">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showArticleContent: false,
        articleTitle: "",//标题
        summary: "",//摘要
        content: "",//内容
      }
    },
    created() {
      this.getMsgInfo()
    },
    watch: {
      //检测路由变化
      '$route'(to, from) {
        this.getMsgInfo()
      },
    },
    mounted() {

    },
    methods: {
      async getMsgInfo() {
        const { token: Authorization, eid } = this.$route.query

        if(this.$route.query.messageId&&this.$route.query.messageId!='undefined'){
          let params,res;
          params = {
            noticeId:this.$route.query.messageId
          }
          res = await this.$api.cj.getMsgInfo(params, { Authorization, eid });

          if (res.retCode == 200) {
            this.noticeTitle = res.data.noticeTitle;
            this.resourceDetail = res.data.resourceDetail;
            this.content = res.data.content.replace(/static.tfcaijing.com/g,'zebra.tfcaijing.com');
            this.showArticleContent = true;
          }
        }else if(sessionStorage.getItem('previewData')) {
          let previewData=JSON.parse(sessionStorage.getItem('previewData'));
          this.noticeTitle = previewData.noticeTitle;
          this.resourceDetail = previewData.resourceDetail;
          this.content = previewData.content.replace(/static.tfcaijing.com/g,'zebra.tfcaijing.com');
          this.showArticleContent=true;
        }

      },
    },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .article-tag {
    width: 3.72rem;
    height: .88rem;
    line-height: .88rem;
    color: #333;
    background: linear-gradient(45deg, #fee300, #ffec3c);
    border-radius: .28rem .28rem .28rem 0;
    display: inline-block;
    font-size: .48rem;
    font-family: "PingFang SC";
    margin: 1.42rem auto 0;
    text-align: center;

  }

  .article-content {
    padding: 0 .88rem;
    margin-top: 0.62rem;
  }

  .article-title {
    font-size: 1.12rem;
    font-family: "PingFangSC-Regular";
    color: #000;
    line-height: 1.36rem;
    font-weight: bold;
    position: relative;
    padding-top: .73rem;
    text-align: left;
  }

  .title-decorate {
    position: absolute;
    width: 1.63rem;
    height: 1.18rem;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .article-summary {
    padding: 0 1.58rem 0 1.58rem;
    font: .56rem "PingFangSC-Regular";
    color: #000;
    line-height: .88rem;
    text-align: left;
  }
  .notice-summary{
    margin-top:1rem;
  }

  .line {
    width: 5.14rem !important;
    height: 1px !important;
    background-color: #ddd !important;
    display: block;
  }

  .summary-line {
    margin: 0.8rem auto 2rem;
  }
</style>
